<template>
  <div>
    <!-- Hero Section -->
    <div 
      class="relative h-[600px] bg-center bg-no-repeat" 
      style="
        background-image: url('/images/hero/hero-bg.jpeg');
        background-size: 100%;  /* Zoom out to show more of the image */
      "
    >
      <!-- Add overlay to ensure text readability -->
      <div class="absolute inset-0 bg-black bg-opacity-50"></div>
      
      <div class="container mx-auto px-4 h-full flex items-center relative z-10">
        <div class="text-white">
          <h1 class="text-5xl font-bold mb-4">Welcome to Praveen Cabs</h1>
          <p class="text-xl mb-8">Your trusted partner for comfortable and safe travels</p>
          <router-link 
            to="/contact" 
            class="bg-primary text-white px-8 py-3 rounded-lg hover:bg-primary-dark"
          >
            Book Now
          </router-link>
        </div>
      </div>
    </div>

    <!-- Popular Packages Section -->
    <section class="py-16">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold text-center mb-12">Popular Packages</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div 
            v-for="trip in popularPackages.filter((t): t is Trip => t !== undefined)" 
            :key="trip.trip_name"
            class="bg-white rounded-lg shadow-md overflow-hidden flex flex-col"
          >
            <div class="h-48 overflow-hidden">
              <img 
                :src="trip.image || '/images/packages/default.jpg'" 
                :alt="trip.trip_name"
                class="w-full h-full object-cover"
              />
            </div>
            
            <div class="p-6 flex flex-col flex-grow">
              <h3 class="text-xl font-semibold mb-2">{{ trip.trip_name }}</h3>
              <p class="text-gray-600 mb-4 line-clamp-2">{{ trip.description }}</p>
              
              <div class="mt-auto flex justify-between items-center pt-4 border-t">
                <div class="text-lg font-bold">
                  From ₹{{ trip.rates.only_vehicle.sedan }}
                </div>
                <router-link 
                  :to="`/packages/${encodeURIComponent(trip.trip_name)}`"
                  class="bg-primary text-white px-6 py-2 rounded hover:bg-primary-dark transition-colors"
                >
                  Book Now
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-8">
          <router-link 
            to="/packages"
            class="inline-block bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary-dark"
          >
            View All Packages
          </router-link>
        </div>
      </div>
    </section>

    <!-- Newsletter Section -->
    <section class="py-16 bg-primary text-white">
      <div class="container mx-auto px-4 text-center">
        <h2 class="text-3xl font-bold mb-6">Stay Updated</h2>
        <p class="text-lg mb-8 max-w-2xl mx-auto">
          Subscribe to our newsletter for exclusive offers and travel updates.
        </p>
        <form @submit.prevent="subscribeToNewsletter" class="max-w-md mx-auto flex gap-4">
          <input
            v-model="email"
            type="email"
            placeholder="Enter your email"
            class="flex-1 px-4 py-2 rounded text-gray-900"
            required
          />
          <button
            type="submit"
            class="bg-white text-primary px-8 py-2 rounded hover:bg-gray-100 transition-colors"
          >
            Subscribe
          </button>
        </form>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import PackageCard from '../components/PackageCard.vue'
import packageData from '../PackageData.json'

interface Trip {
  trip_name: string;
  description: string;
  image: string;
  rates: {
    with_hotel: {
      sedan: number;
      ertiga: number;
      innova: number;
    };
    only_vehicle: {
      sedan: number;
      ertiga: number;
      innova: number;
    };
  };
}

const email = ref('')

// Get 3 popular packages (1 from Chennai, 1 from Coimbatore, 1 from others)
const popularPackages = computed(() => {
  const chennaiPackage = packageData.trips.find((trip: Trip) => 
    trip.trip_name.includes('Chennai-Ooty')
  )
  
  const coimbatorePackage = packageData.trips.find((trip: Trip) => 
    trip.trip_name.includes('Coimbatore-Ooty')
  )
  
  const otherPackage = packageData.trips.find((trip: Trip) => 
    trip.trip_name.includes('Kochi-Trivandrum')
  )

  return [chennaiPackage, coimbatorePackage, otherPackage].filter(Boolean)
})


const subscribeToNewsletter = async () => {
  console.log('Subscribe:', email.value)
  email.value = ''
}
</script>

<style>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style> 