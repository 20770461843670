import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "min-h-screen bg-gray-50 flex flex-col" }
const _hoisted_2 = { class: "bg-white shadow-lg fixed w-full z-50" }
const _hoisted_3 = { class: "container mx-auto px-4" }
const _hoisted_4 = { class: "flex justify-between items-center h-16" }
const _hoisted_5 = { class: "hidden md:flex space-x-8" }
const _hoisted_6 = {
  class: "w-6 h-6",
  fill: "none",
  stroke: "currentColor",
  viewBox: "0 0 24 24"
}
const _hoisted_7 = {
  key: 0,
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "2",
  d: "M4 6h16M4 12h16M4 18h16"
}
const _hoisted_8 = {
  key: 1,
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "2",
  d: "M6 18L18 6M6 6l12 12"
}
const _hoisted_9 = { class: "md:hidden pb-4" }
const _hoisted_10 = { class: "flex-grow" }

import { ref } from 'vue'
import AppFooter from './components/AppFooter.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const isMenuOpen = ref(false)

const navItems = [
  { name: 'Home', path: '/' },
  { name: 'About', path: '/about' },
  { name: 'Packages', path: '/packages' },
  { name: 'Contact', path: '/contact' }
]

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("nav", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "text-2xl font-bold text-primary"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Praveen Cabs ")
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navItems, (item) => {
              return _createVNode(_component_router_link, {
                key: item.path,
                to: item.path,
                class: "text-gray-700 hover:text-primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            }), 64))
          ]),
          _createVNode(_component_router_link, {
            to: "/contact",
            class: "bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary-dark"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Book Now ")
            ])),
            _: 1
          }),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isMenuOpen.value = !isMenuOpen.value)),
            class: "md:hidden text-gray-700"
          }, [
            (_openBlock(), _createElementBlock("svg", _hoisted_6, [
              (!isMenuOpen.value)
                ? (_openBlock(), _createElementBlock("path", _hoisted_7))
                : (_openBlock(), _createElementBlock("path", _hoisted_8))
            ]))
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_9, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(navItems, (item) => {
            return _createVNode(_component_router_link, {
              key: item.path,
              to: item.path,
              class: "block py-2 text-gray-700 hover:text-primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (isMenuOpen.value = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          }), 64))
        ], 512), [
          [_vShow, isMenuOpen.value]
        ])
      ])
    ]),
    _createElementVNode("main", _hoisted_10, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(AppFooter)
  ]))
}
}

})