import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "relative h-[600px] bg-center bg-no-repeat",
  style: {"background-image":"url('/images/hero/hero-bg.jpeg')","background-size":"100%"}
}
const _hoisted_2 = { class: "container mx-auto px-4 h-full flex items-center relative z-10" }
const _hoisted_3 = { class: "text-white" }
const _hoisted_4 = { class: "py-16" }
const _hoisted_5 = { class: "container mx-auto px-4" }
const _hoisted_6 = { class: "grid grid-cols-1 md:grid-cols-3 gap-8" }
const _hoisted_7 = { class: "h-48 overflow-hidden" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "p-6 flex flex-col flex-grow" }
const _hoisted_10 = { class: "text-xl font-semibold mb-2" }
const _hoisted_11 = { class: "text-gray-600 mb-4 line-clamp-2" }
const _hoisted_12 = { class: "mt-auto flex justify-between items-center pt-4 border-t" }
const _hoisted_13 = { class: "text-lg font-bold" }
const _hoisted_14 = { class: "text-center mt-8" }
const _hoisted_15 = { class: "py-16 bg-primary text-white" }
const _hoisted_16 = { class: "container mx-auto px-4 text-center" }

import { ref, computed } from 'vue'
import PackageCard from '../components/PackageCard.vue'
import packageData from '../PackageData.json'

interface Trip {
  trip_name: string;
  description: string;
  image: string;
  rates: {
    with_hotel: {
      sedan: number;
      ertiga: number;
      innova: number;
    };
    only_vehicle: {
      sedan: number;
      ertiga: number;
      innova: number;
    };
  };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const email = ref('')

// Get 3 popular packages (1 from Chennai, 1 from Coimbatore, 1 from others)
const popularPackages = computed(() => {
  const chennaiPackage = packageData.trips.find((trip: Trip) => 
    trip.trip_name.includes('Chennai-Ooty')
  )
  
  const coimbatorePackage = packageData.trips.find((trip: Trip) => 
    trip.trip_name.includes('Coimbatore-Ooty')
  )
  
  const otherPackage = packageData.trips.find((trip: Trip) => 
    trip.trip_name.includes('Kochi-Trivandrum')
  )

  return [chennaiPackage, coimbatorePackage, otherPackage].filter(Boolean)
})


const subscribeToNewsletter = async () => {
  console.log('Subscribe:', email.value)
  email.value = ''
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "absolute inset-0 bg-black bg-opacity-50" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "text-5xl font-bold mb-4" }, "Welcome to Praveen Cabs", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-xl mb-8" }, "Your trusted partner for comfortable and safe travels", -1)),
          _createVNode(_component_router_link, {
            to: "/contact",
            class: "bg-primary text-white px-8 py-3 rounded-lg hover:bg-primary-dark"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Book Now ")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "text-3xl font-bold text-center mb-12" }, "Popular Packages", -1)),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(popularPackages.value.filter((t): t is Trip => t !== undefined), (trip) => {
            return (_openBlock(), _createElementBlock("div", {
              key: trip.trip_name,
              class: "bg-white rounded-lg shadow-md overflow-hidden flex flex-col"
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: trip.image || '/images/packages/default.jpg',
                  alt: trip.trip_name,
                  class: "w-full h-full object-cover"
                }, null, 8, _hoisted_8)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("h3", _hoisted_10, _toDisplayString(trip.trip_name), 1),
                _createElementVNode("p", _hoisted_11, _toDisplayString(trip.description), 1),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, " From ₹" + _toDisplayString(trip.rates.only_vehicle.sedan), 1),
                  _createVNode(_component_router_link, {
                    to: `/packages/${encodeURIComponent(trip.trip_name)}`,
                    class: "bg-primary text-white px-6 py-2 rounded hover:bg-primary-dark transition-colors"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Book Now ")
                    ])),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_router_link, {
            to: "/packages",
            class: "inline-block bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary-dark"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" View All Packages ")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "text-3xl font-bold mb-6" }, "Stay Updated", -1)),
        _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-lg mb-8 max-w-2xl mx-auto" }, " Subscribe to our newsletter for exclusive offers and travel updates. ", -1)),
        _createElementVNode("form", {
          onSubmit: _withModifiers(subscribeToNewsletter, ["prevent"]),
          class: "max-w-md mx-auto flex gap-4"
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
            type: "email",
            placeholder: "Enter your email",
            class: "flex-1 px-4 py-2 rounded text-gray-900",
            required: ""
          }, null, 512), [
            [_vModelText, email.value]
          ]),
          _cache[8] || (_cache[8] = _createElementVNode("button", {
            type: "submit",
            class: "bg-white text-primary px-8 py-2 rounded hover:bg-gray-100 transition-colors"
          }, " Subscribe ", -1))
        ], 32)
      ])
    ])
  ]))
}
}

})