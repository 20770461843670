import { createApp } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import App from './App.vue'

// Import views
import HomePage from './views/HomePage.vue'
import AboutPage from './views/AboutPage.vue'
import PackagePage from './views/PackagePage.vue'
import ContactPage from './views/ContactPage.vue'

// Import Tailwind CSS
import './index.css'

// Configure router
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/packages',
    name: 'Packages',
    component: PackagePage
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage
  },
  {
    path: '/packages/:tripName',
    name: 'BookPackage',
    component: () => import('./views/BookingPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

// Create Vue application
const app = createApp(App)

// Use router
app.use(router)

// Mount application
app.mount('#app') 