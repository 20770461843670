<template>
  <div class="min-h-screen pt-20">
    <!-- Hero Section -->
    <section class="relative py-16 bg-gray-50">
      <div class="container mx-auto px-4">
        <h1 class="text-4xl md:text-5xl font-bold text-center mb-6">
          Contact Us
        </h1>
        <p class="text-xl text-gray-600 text-center max-w-3xl mx-auto">
          Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible.
        </p>
      </div>
    </section>

    <!-- Contact Form Section -->
    <section class="py-16">
      <div class="container mx-auto px-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
          <!-- Contact Form -->
          <div class="bg-white p-8 rounded-lg shadow-md">
            <h2 class="text-2xl font-bold mb-6">Send us a Message</h2>
            <form @submit.prevent="handleSubmit" class="space-y-6">
              <div>
                <label class="block text-gray-700 mb-2">Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  required
                  class="w-full px-4 py-2 rounded border focus:outline-none focus:border-primary"
                />
              </div>

              <div>
                <label class="block text-gray-700 mb-2">Email</label>
                <input
                  v-model="form.email"
                  type="email"
                  required
                  class="w-full px-4 py-2 rounded border focus:outline-none focus:border-primary"
                />
              </div>

              <div>
                <label class="block text-gray-700 mb-2">Subject</label>
                <input
                  v-model="form.subject"
                  type="text"
                  required
                  class="w-full px-4 py-2 rounded border focus:outline-none focus:border-primary"
                />
              </div>

              <div>
                <label class="block text-gray-700 mb-2">Message</label>
                <textarea
                  v-model="form.message"
                  required
                  rows="4"
                  class="w-full px-4 py-2 rounded border focus:outline-none focus:border-primary"
                ></textarea>
              </div>

              <button
                type="submit"
                class="w-full bg-primary text-white px-6 py-3 rounded hover:bg-primary-dark transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>

          <!-- Contact Info -->
          <div class="space-y-8">
            <div>
              <h2 class="text-2xl font-bold mb-6">Get in Touch</h2>
              <div class="space-y-4">
                <div class="flex items-start space-x-4">
                  <i class="fas fa-map-marker-alt text-primary text-xl mt-1"></i>
                  <div>
                    <h3 class="font-bold">Address</h3>
                    <p class="text-gray-600">6,Poonthottam, Velammal Nagar,Perungudi,Madurai,Tamil Nadu,625022</p>
                  </div>
                </div>

                <div class="flex items-start space-x-4">
                  <i class="fas fa-phone text-primary text-xl mt-1"></i>
                  <div>
                    <h3 class="font-bold">Phone</h3>
                    <p class="text-gray-600">+91 9443679513</p>
                  </div>
                </div>

                <div class="flex items-start space-x-4">
                  <i class="fas fa-envelope text-primary text-xl mt-1"></i>
                  <div>
                    <h3 class="font-bold">Email</h3>
                    <p class="text-gray-600">maduraipraveencabs@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- WhatsApp Chat -->
            <div class="bg-green-50 p-6 rounded-lg">
              <h3 class="text-xl font-bold mb-4">Chat with Us on WhatsApp</h3>
              <p class="text-gray-600 mb-4">
                Get instant responses to your queries through WhatsApp.
              </p>
              <a
                :href="whatsappLink"
                target="_blank"
                rel="noopener noreferrer"
                class="inline-flex items-center space-x-2 bg-green-500 text-white px-6 py-3 rounded hover:bg-green-600 transition-colors"
              >
                <i class="fab fa-whatsapp text-xl"></i>
                <span>Start Chat</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const form = ref({
  name: '',
  email: '',
  subject: '',
  message: ''
})

const whatsappLink = 'https://wa.me/+918270770369' // Replace with your WhatsApp number

const handleSubmit = async () => {
  try {
    // Implement form submission logic here
    console.log('Form submitted:', form.value)
    
    // Reset form
    form.value = {
      name: '',
      email: '',
      subject: '',
      message: ''
    }
    
    // Show success message
    alert('Message sent successfully!')
  } catch (error) {
    console.error('Error submitting form:', error)
    alert('Error sending message. Please try again.')
  }
}
</script> 