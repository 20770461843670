import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-h-screen pt-20" }
const _hoisted_2 = { class: "py-16" }
const _hoisted_3 = { class: "container mx-auto px-4" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-2 gap-12" }
const _hoisted_5 = { class: "bg-white p-8 rounded-lg shadow-md" }

import { ref } from 'vue'

const whatsappLink = 'https://wa.me/+918270770369' // Replace with your WhatsApp number


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactPage',
  setup(__props) {

const form = ref({
  name: '',
  email: '',
  subject: '',
  message: ''
})

const handleSubmit = async () => {
  try {
    // Implement form submission logic here
    console.log('Form submitted:', form.value)
    
    // Reset form
    form.value = {
      name: '',
      email: '',
      subject: '',
      message: ''
    }
    
    // Show success message
    alert('Message sent successfully!')
  } catch (error) {
    console.error('Error submitting form:', error)
    alert('Error sending message. Please try again.')
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("section", { class: "relative py-16 bg-gray-50" }, [
      _createElementVNode("div", { class: "container mx-auto px-4" }, [
        _createElementVNode("h1", { class: "text-4xl md:text-5xl font-bold text-center mb-6" }, " Contact Us "),
        _createElementVNode("p", { class: "text-xl text-gray-600 text-center max-w-3xl mx-auto" }, " Have questions? We'd love to hear from you. Send us a message and we'll respond as soon as possible. ")
      ])
    ], -1)),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[9] || (_cache[9] = _createElementVNode("h2", { class: "text-2xl font-bold mb-6" }, "Send us a Message", -1)),
            _createElementVNode("form", {
              onSubmit: _withModifiers(handleSubmit, ["prevent"]),
              class: "space-y-6"
            }, [
              _createElementVNode("div", null, [
                _cache[4] || (_cache[4] = _createElementVNode("label", { class: "block text-gray-700 mb-2" }, "Name", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name) = $event)),
                  type: "text",
                  required: "",
                  class: "w-full px-4 py-2 rounded border focus:outline-none focus:border-primary"
                }, null, 512), [
                  [_vModelText, form.value.name]
                ])
              ]),
              _createElementVNode("div", null, [
                _cache[5] || (_cache[5] = _createElementVNode("label", { class: "block text-gray-700 mb-2" }, "Email", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.email) = $event)),
                  type: "email",
                  required: "",
                  class: "w-full px-4 py-2 rounded border focus:outline-none focus:border-primary"
                }, null, 512), [
                  [_vModelText, form.value.email]
                ])
              ]),
              _createElementVNode("div", null, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "block text-gray-700 mb-2" }, "Subject", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.subject) = $event)),
                  type: "text",
                  required: "",
                  class: "w-full px-4 py-2 rounded border focus:outline-none focus:border-primary"
                }, null, 512), [
                  [_vModelText, form.value.subject]
                ])
              ]),
              _createElementVNode("div", null, [
                _cache[7] || (_cache[7] = _createElementVNode("label", { class: "block text-gray-700 mb-2" }, "Message", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.message) = $event)),
                  required: "",
                  rows: "4",
                  class: "w-full px-4 py-2 rounded border focus:outline-none focus:border-primary"
                }, null, 512), [
                  [_vModelText, form.value.message]
                ])
              ]),
              _cache[8] || (_cache[8] = _createElementVNode("button", {
                type: "submit",
                class: "w-full bg-primary text-white px-6 py-3 rounded hover:bg-primary-dark transition-colors"
              }, " Send Message ", -1))
            ], 32)
          ]),
          _createElementVNode("div", { class: "space-y-8" }, [
            _cache[13] || (_cache[13] = _createStaticVNode("<div><h2 class=\"text-2xl font-bold mb-6\">Get in Touch</h2><div class=\"space-y-4\"><div class=\"flex items-start space-x-4\"><i class=\"fas fa-map-marker-alt text-primary text-xl mt-1\"></i><div><h3 class=\"font-bold\">Address</h3><p class=\"text-gray-600\">6,Poonthottam, Velammal Nagar,Perungudi,Madurai,Tamil Nadu,625022</p></div></div><div class=\"flex items-start space-x-4\"><i class=\"fas fa-phone text-primary text-xl mt-1\"></i><div><h3 class=\"font-bold\">Phone</h3><p class=\"text-gray-600\">+91 9443679513</p></div></div><div class=\"flex items-start space-x-4\"><i class=\"fas fa-envelope text-primary text-xl mt-1\"></i><div><h3 class=\"font-bold\">Email</h3><p class=\"text-gray-600\">maduraipraveencabs@gmail.com</p></div></div></div></div>", 1)),
            _createElementVNode("div", { class: "bg-green-50 p-6 rounded-lg" }, [
              _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "text-xl font-bold mb-4" }, "Chat with Us on WhatsApp", -1)),
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-gray-600 mb-4" }, " Get instant responses to your queries through WhatsApp. ", -1)),
              _createElementVNode("a", {
                href: whatsappLink,
                target: "_blank",
                rel: "noopener noreferrer",
                class: "inline-flex items-center space-x-2 bg-green-500 text-white px-6 py-3 rounded hover:bg-green-600 transition-colors"
              }, _cache[10] || (_cache[10] = [
                _createElementVNode("i", { class: "fab fa-whatsapp text-xl" }, null, -1),
                _createElementVNode("span", null, "Start Chat", -1)
              ]))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})