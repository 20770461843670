<template>
  <div class="min-h-screen pt-20">
    <!-- Hero Section -->
    <section class="relative py-16 bg-gray-50">
      <div class="container mx-auto px-4">
        <h1 class="text-4xl md:text-5xl font-bold text-center mb-6">
          Travel Packages
        </h1>
        <p class="text-xl text-gray-600 text-center max-w-3xl mx-auto mb-8">
          Choose from our carefully curated travel packages for an unforgettable journey.
        </p>
      </div>
    </section>

    <!-- Packages Grid -->
    <section class="py-16">
      <div class="container mx-auto px-4">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div 
            v-for="trip in packageData.trips" 
            :key="trip.trip_name"
            class="bg-white rounded-lg shadow-md overflow-hidden flex flex-col"
          >
            <div class="h-48 overflow-hidden">
              <img 
                :src="trip.image" 
                :alt="trip.trip_name"
                @error="handleImageError"
                class="w-full h-full object-cover"
              />
            </div>
            
            <div class="p-6 flex flex-col flex-grow">
              <h3 class="text-xl font-semibold mb-2">{{ trip.trip_name }}</h3>
              <p class="text-gray-600 mb-4 line-clamp-2">{{ trip.description }}</p>
              
              <div class="mt-auto flex justify-between items-center pt-4 border-t">
                <div class="text-lg font-bold">
                  From ₹{{ trip.rates.only_vehicle.sedan }}
                </div>
                <router-link 
                  :to="`/packages/${encodeURIComponent(trip.trip_name)}`"
                  class="bg-primary text-white px-6 py-2 rounded hover:bg-primary-dark transition-colors"
                >
                  Book Now
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import packageData from '@/PackageData.json'

const handleImageError = (e: Event) => {
  const img = e.target as HTMLImageElement
  img.src = '/images/packages/default.jpg'  // Fallback image
}
</script>

<style>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style> 