import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "min-h-screen pt-20" }
const _hoisted_2 = { class: "py-16" }
const _hoisted_3 = { class: "container mx-auto px-4" }
const _hoisted_4 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" }
const _hoisted_5 = { class: "h-48 overflow-hidden" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "p-6 flex flex-col flex-grow" }
const _hoisted_8 = { class: "text-xl font-semibold mb-2" }
const _hoisted_9 = { class: "text-gray-600 mb-4 line-clamp-2" }
const _hoisted_10 = { class: "mt-auto flex justify-between items-center pt-4 border-t" }
const _hoisted_11 = { class: "text-lg font-bold" }

import { computed } from 'vue'
import packageData from '@/PackageData.json'


export default /*@__PURE__*/_defineComponent({
  __name: 'PackagePage',
  setup(__props) {

const handleImageError = (e: Event) => {
  const img = e.target as HTMLImageElement
  img.src = '/images/packages/default.jpg'  // Fallback image
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("section", { class: "relative py-16 bg-gray-50" }, [
      _createElementVNode("div", { class: "container mx-auto px-4" }, [
        _createElementVNode("h1", { class: "text-4xl md:text-5xl font-bold text-center mb-6" }, " Travel Packages "),
        _createElementVNode("p", { class: "text-xl text-gray-600 text-center max-w-3xl mx-auto mb-8" }, " Choose from our carefully curated travel packages for an unforgettable journey. ")
      ])
    ], -1)),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(packageData).trips, (trip) => {
            return (_openBlock(), _createElementBlock("div", {
              key: trip.trip_name,
              class: "bg-white rounded-lg shadow-md overflow-hidden flex flex-col"
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: trip.image,
                  alt: trip.trip_name,
                  onError: handleImageError,
                  class: "w-full h-full object-cover"
                }, null, 40, _hoisted_6)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", _hoisted_8, _toDisplayString(trip.trip_name), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(trip.description), 1),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, " From ₹" + _toDisplayString(trip.rates.only_vehicle.sedan), 1),
                  _createVNode(_component_router_link, {
                    to: `/packages/${encodeURIComponent(trip.trip_name)}`,
                    class: "bg-primary text-white px-6 py-2 rounded hover:bg-primary-dark transition-colors"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" Book Now ")
                    ])),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})