<template>
  <div class="min-h-screen bg-gray-50 flex flex-col">
    <!-- Navigation Header -->
    <nav class="bg-white shadow-lg fixed w-full z-50">
      <div class="container mx-auto px-4">
        <div class="flex justify-between items-center h-16">
          <!-- Logo -->
          <router-link to="/" class="text-2xl font-bold text-primary">
            Praveen Cabs
          </router-link>

          <!-- Navigation Links -->
          <div class="hidden md:flex space-x-8">
            <router-link 
              v-for="item in navItems" 
              :key="item.path"
              :to="item.path"
              class="text-gray-700 hover:text-primary"
            >
              {{ item.name }}
            </router-link>
          </div>

          <!-- Book Now Button -->
          <router-link 
            to="/contact"
            class="bg-primary text-white px-6 py-2 rounded-lg hover:bg-primary-dark"
          >
            Book Now
          </router-link>

          <!-- Mobile Menu Button -->
          <button 
            @click="isMenuOpen = !isMenuOpen"
            class="md:hidden text-gray-700"
          >
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path v-if="!isMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
              <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>

        <!-- Mobile Menu -->
        <div 
          v-show="isMenuOpen" 
          class="md:hidden pb-4"
        >
          <router-link 
            v-for="item in navItems" 
            :key="item.path"
            :to="item.path"
            class="block py-2 text-gray-700 hover:text-primary"
            @click="isMenuOpen = false"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
    </nav>

    <!-- Main Content -->
    <main class="flex-grow">
      <router-view></router-view>
    </main>

    <!-- Footer -->
    <app-footer />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import AppFooter from './components/AppFooter.vue'

const isMenuOpen = ref(false)

const navItems = [
  { name: 'Home', path: '/' },
  { name: 'About', path: '/about' },
  { name: 'Packages', path: '/packages' },
  { name: 'Contact', path: '/contact' }
]
</script>

<style>
.router-link-active {
  color: #3B82F6; /* primary color */
  font-weight: 500;
}
</style> 